import React, { useContext, useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { dribbbleIcon, linkedInIcon, github, instaIcon } from '../SvgComponents/socialIcons';
import PortfolioContext from '../../context/context';
import IconLink from '../Buttons/IconLink';
import HandWaveEmoji from '../../images/handwave.png';
import gsap from 'gsap';
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import { animated, useSpring } from 'react-spring';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const layoutContents = useRef();
  const transitionCover = useRef();
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    textEffect();
  }, []);


  //title animation
  const config = { duration: 600 };


  const exitHorizontal = ({ length }, direction) => {
    const seconds = length;

    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';

    return gsap
      .timeline()
      .set(transitionCover.current, { x: directionFrom, display: 'block' })
      .to(transitionCover.current, {
        x: '0%',
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      })
      .set(layoutContents.current, { opacity: 0 })
      .to(transitionCover.current, {
        x: directionTo,
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      });
  };


  //GSAP mask in text 
  const textEffect = () => {
    return gsap.timeline({
      defaults: {
        delay: 0.8,
        duration: 1.2,
        stagger: 0.5,
        ease: "expo.out",
      },
    
    }).from(".line .line--text", {
      yPercent: 340,
      skewY: 5,
    })
      .to(".line .line--text", {
        yPercent: 0,
        skewY: 0,
      });
  }


  return (
    <section id="hero" className="jumbotron" ref={layoutContents}>
      <Container className='px-0'>
        <div className="lines">
          <p className="line">
            <span className="line--text hero-title mb-0">Zabi <img src={HandWaveEmoji} className="wave-emoji ml-4 mb-2" /></span>
          </p>

          <p className="line">
            <span className="line--text hero-sub-title">Developer. Designer.</span>
          </p>
          <Fade bottom duration={1000} delay={1600} distance="20px">
          <p className="hero-cta mt-4">
            <span className="cta-btn cta-btn--hero">
              <TransitionLink
                to="/portfolio"
                exit={{
                  length: 1,
                  trigger: ({ exit }) => exitHorizontal(exit, 'left'),
                }}
                entry={{
                  delay: 1,
                  state: { data: 'xandar' },
                }}
              >
                Explore my portfolio
              </TransitionLink>
            </span>
          </p>
        </Fade>
        <Fade bottom duration={1000} delay={1800} distance="10px">
          <div className="text-left mt-4 row hero-social-icons">
            <div className="col">
              <IconLink svgIcon={github} to="https://github.com/M-Zabi"/>
            </div>
            <div className="col">
              <IconLink svgIcon={linkedInIcon} to="https://www.linkedin.com/in/mohammed-zabiullah-834975157/"/>
            </div>
          
            <div className="col">
              <IconLink svgIcon={dribbbleIcon} to="https://dribbble.com/zarkaanstudios"/>
            </div>
          </div>
        </Fade>
         
        </div>
      </Container>



      <TransitionPortal>
        <div
          ref={transitionCover}
          style={{
            position: 'fixed',
            background: '#131313',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'none',
          }}
        />
      </TransitionPortal>
    </section>
  );
};

export default Header;
