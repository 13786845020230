import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import HeroLayout from '../components/Layouts/HeroLayout';

const Home = () => {
  const { title, lang, description } = headData;

  return (
    <HeroLayout metaTitle="Developer | Designer" metaDescription='Developer & Designer based in Bengaluru, KA'>
      <App />
    </HeroLayout>
  );
};

export default Home;
