import React, { useState, useEffect } from 'react';

const IconLink = (props) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <a href={props.to} target='_blank' className="round-btn">
      {props.svgIcon ? (
        props.svgIcon
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobile ? '24px' : '30px'}
          height={isMobile ? '24px' : '30px'}
          viewBox="0 0 24.488 24.487"
        >
          <path
            id="google-plus3"
            d="M10.449,19.564c0,1.8-1.11,3.214-4.251,3.328a12.294,12.294,0,0,1-4.469-4.373,4.6,4.6,0,0,1,4.359-2.442,5.378,5.378,0,0,1,1.6.256c1.337.93,2.415,1.51,2.686,2.57a2.879,2.879,0,0,1,.079.661ZM12.243,0A12.209,12.209,0,0,0,3.284,3.9a6.274,6.274,0,0,1,3.035-.78h6.132l-1.37,1.438H9.472a3.874,3.874,0,0,1,1.74,3.468A4.173,4.173,0,0,1,9.4,11.3c-1.035.808-1.231,1.147-1.231,1.835,0,.587,1.237,1.461,1.805,1.888,1.98,1.485,2.381,2.42,2.381,4.277,0,1.882-1.648,3.757-4.444,4.394A12.244,12.244,0,1,0,12.245,0Zm6.122,9.182v3.061h-1.53V9.182H13.773V7.652h3.061V4.591h1.53V7.652h3.061v1.53ZM8.746,8.117c.285,2.171-.666,3.567-2.318,3.518A4.014,4.014,0,0,1,2.919,7.9c-.285-2.171.823-3.832,2.475-3.783s3.066,1.83,3.351,4ZM5.282,15.354A6.7,6.7,0,0,0,.91,16.881,12.286,12.286,0,0,1,.629,8.36a4.369,4.369,0,0,0,4.614,4.213c.2,0,.4-.011.595-.024a2.606,2.606,0,0,0-.326,1.2,2.144,2.144,0,0,0,.881,1.6c-.368,0-.724.01-1.113.01Z"
            fill="#5b5b5b"
          />
        </svg>
      )}
    </a>
  );
};

export default IconLink;
